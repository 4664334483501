@import "variables";

.tasks {
  .ant-collapse-content {
    background-color: $white !important;
  }

  .tasks-list-tabs {
    .ant-tabs-nav {
      padding: 0 24px;
    }

    .ant-list-item-meta {
      padding: 0 24px
    }

    .tasks-list-item-active {
      background-color: $blue-6;

      span {
        color: $white;
      }
    }

    .tasks-stop-btn {
      width: 90%;
      margin: 10px 0;

      span {
        color: black;
      }
    }

    .ant-list-pagination {
      @include flex-space-around;
      margin-bottom: 24px;
    }
  }

}

.new-task {
  .ant-drawer-body {
    padding: 16px 24px;
  }

  .new-task-start-btn, .new-task-cancel-btn {
    width: 100%;
    font-size: 14px;
  }

  .new-task-start-btn {
    margin: 30px 0 16px;
    color: $white;
    background-color: $green-6;
  }

}