@import "variables";

.divider-bar {
  height: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  color: $blue-6;
  font-weight: bold;
  font-size: 12px;
  padding: 4px 24px;

  @include flex-space-between;
}

.title {
  font-size: 12px;
  color: $blue-6;
  line-height: 20px;
  font-weight: bold;
}

.stop-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: $gray-9;
  border-radius: 2px;
  margin-right: 6px;
}