@import "variables";

.features {
  .ant-drawer-header {
    padding: 16px 12px;
  }

  .feature-list {
    .feature-list-btns {
      @include flex-space-between;
      padding: 0 24px 12px;
    }

    .list-item-active {
      background-color: $blue-6;

      span {
        color: $white;
      }
    }

    .feature-list-new-task-btn {
      display: inline-flex;
      align-items: center;
      flex-direction: row-reverse;

      span {
        color: $blue-6;
        margin: 0 4px;
      }
    }

    .ant-list-pagination {
      @include flex-space-around;
      margin-bottom: 24px;
    }
  }
}