@import "variables";

.leaflet-control-attribution {
  display: none;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-popup-pane {
  font-family: $font-family;

  .leaflet-popup-content-wrapper {
    border-radius: 2px;
    width: 256px;
    background-color: $gray-2;

    .leaflet-popup-content {
      margin: 16px;

      .popup-title {
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
      }

      .ant-descriptions-item {
        color: $gray-10;
        padding-bottom: 2px;

        .ant-descriptions-item-label {
          padding-top: 8px;
          font-size: 12px;
          line-height: 16px;
        }

        .ant-descriptions-item-content {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
        }
      }

      .popup-date {
        margin: 14px 0;
        font-size: 10px;
        color: $gray-7;
        font-style: italic;
      }

      .popup-button {
        font-size: 12px;
      }
    }

    .ant-descriptions-header {
      margin-bottom: 0;
    }
  }

  .leaflet-popup-close-button {
    top: 8px !important;
    right: 8px !important;
  }
}