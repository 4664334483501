@import "variables";

.menu {
  z-index: 2000;
  padding: 30px 0 14px;
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;

  .ant-layout-sider-children {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .logo-wrapper {
    @include flex-center;

    margin: 10px auto;
    width: 100%;

    .logo {
      width: 60%;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  margin: 0 0 10px 10px;
  color: #ffffff;
  z-index: 1000;
}
