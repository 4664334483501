@import "variables";

.file {
  .file-list {
    .ant-list-pagination {
      @include flex-space-around;
      margin-bottom: 24px;
    }

    .paths-list-item-active {
      background-color: $blue-6;

      span {
        color: $white;
      }
    }
  }

}