@import "variables";

.ant-drawer-body::-webkit-scrollbar {
  display: none;
}

.ant-drawer-title {
  font-weight: bold;
  font-size: 16px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-list-item-meta-title {
  margin: 0;
}

.ant-list-item-meta-description {
  margin-bottom: 4px;
  font-size: 12px;
  padding-left: 18px;
}

.ant-descriptions-title {
  font-size: 20px;
  font-weight: 600;
}

.ant-list-item-action {
  margin-left: 12px;
}

.ant-list-item {
  &:nth-child(even) {
    background-color: $gray-2;
  }

  &:nth-child(odd) {
    background-color: $white;
  }
}

.ant-collapse-item {
  .ant-collapse-header {
    height: 40px;
    background-color: $white;
    font-weight: bold;
    font-size: 12px;
    padding-left: 24px !important;

    .ant-collapse-header-text {
      color: $blue-6;
    }
  }

  .ant-collapse-content {
    background-color: $white !important;

    .ant-collapse-content-box {
      padding: 4px 26px 26px;

      .ant-descriptions-header {
        margin-bottom: 0;
      }

      .ant-descriptions-item {
        padding: 0 8px 0 0;

        .ant-descriptions-item-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;

          &::after {
            content: ""
          }
        }

        .ant-descriptions-item-content {
          font-size: 14px;
          font-weight: 600 !important;
          line-height: 22px;
        }
      }
    }
  }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon{
  font-size: 20px;
}