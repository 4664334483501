@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import '~antd/dist/antd.css';
@import "menu";
@import "file";
@import "variables.scss";
@import "tasks";
@import "features";
@import "ant";
@import "common";

body {
  margin: 0;
  color: $gray-10;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
