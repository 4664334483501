$blue-6: #1890ff;
$white: #FFFFFF;
$green-6: #209F6F;
$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-7: #8C8C8C;
$gray-9: #434343;
$gray-10: #262626;
$font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

@mixin flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  color: $blue-6;
}

.white {
  color: $white;
}

.flex-space-between {
  @include flex-space-between;
}